<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>관리자 - 시스템설정</h1>
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <v-btn color="#FF4D00" dark block @click="$router.push('/admin')">
          BACK
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-card height="100%">
          <v-card-title class="pa-0">
            <v-toolbar color="#083272" dark>
              <v-toolbar-title>
                <h3 class="font-weight-bold suptitle">유지보수 ON/OFF</h3>
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card-title>
          <v-card-text class="py-2">
            <v-container class="pa-0 py-2">
              <v-row class="pb-0">
                <v-col cols="12" class="pb-0 text-center">
                  <h3>유지보수모드를 켜고 끕니다.</h3>
                </v-col>
              </v-row>
              <v-row class="pt-0">
                <v-spacer class="py-0"></v-spacer>
                <v-col class="py-0" cols="auto">
                  <v-switch
                    v-model="maintenance.state"
                    inset
                    @change="changeMaintenace"
                  ></v-switch>
                </v-col>
                <v-spacer class="py-0"></v-spacer>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card height="100%">
          <v-card-title class="pa-0">
            <v-toolbar color="#083272" dark>
              <v-toolbar-title>
                <h3 class="font-weight-bold suptitle">시간제한 ON/OFF</h3>
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card-title>

          <v-card-text class="py-2">
            <v-container class="pa-0 py-2">
              <v-row class="pb-0">
                <v-col cols="12" class="pb-0 text-center">
                  <h3>시간제한모드를 켜고 끕니다.</h3>
                </v-col>
              </v-row>
              <v-row class="pt-0">
                <v-spacer class="py-0"></v-spacer>
                <v-col class="py-0" cols="auto">
                  <v-switch
                    v-model="servicedown.state"
                    inset
                    @change="changeServiceDown"
                  ></v-switch>
                </v-col>
                <v-spacer class="py-0"></v-spacer>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text class="py-2">
            <v-layout>
              <v-flex class="px-1">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="start"
                  v-model="servicedown.startTime"
                ></v-text-field>
              </v-flex>
              <v-flex class="px-1">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="end"
                  v-model="servicedown.endTime"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-text class="px-5 pb-2">
            <v-textarea
              outlined
              name="input-7-4"
              label="문구 (html)"
              v-model="servicedown.downMessage"
              hide-details=""
            ></v-textarea>
          </v-card-text>
          <v-card-text class="py-0 pb-4 px-5">
            <v-btn block color="success" @click="changeServiceDownState">
              <v-icon class="mr-3">mdi-floppy</v-icon> 저장</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card height="100%">
          <v-card-title class="pa-0">
            <v-toolbar color="#083272" dark>
              <v-toolbar-title>
                <h3 class="font-weight-bold suptitle">Read-Only ON/OFF</h3>
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card-title>

          <v-card-text class="py-2">
            <v-container class="pa-0 py-2">
              <v-row class="pb-0">
                <v-col cols="12" class="pb-0 text-center">
                  <h3>
                    아래 시간을 제외한 시간동안 Read-Only 모드를 활성화 합니다.
                  </h3>
                </v-col>
              </v-row>
              <v-row class="pt-0">
                <v-spacer class="py-0"></v-spacer>
                <v-col class="py-0" cols="auto">
                  <v-switch
                    v-model="readonly.state"
                    inset
                    @change="changeReadonlyOn"
                  ></v-switch>
                </v-col>
                <v-spacer class="py-0"></v-spacer>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text class="py-2">
            <v-layout>
              <v-flex class="px-1">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="start"
                  v-model="readonly.startTime"
                ></v-text-field>
              </v-flex>
              <v-flex class="px-1">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="end"
                  v-model="readonly.endTime"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-text class="py-0 pb-4 px-5">
            <v-btn block color="success" @click="changeReadonlyStatus">
              <v-icon class="mr-3">mdi-floppy</v-icon> 저장</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { updateSystemSettings } from "@/api/System";
export default {
  mounted() {
    this.maintenance.state = this.IS_CHECK_TIME;
    this.servicedown.state = this.SERVICE_DOWN_ON;
    this.servicedown.startTime = this.SERVICE_START_TIME;
    this.servicedown.endTime = this.SERVICE_END_TIME;
    this.servicedown.downMessage = this.SERVICE_DOWN_MESSAGE;

    this.readonly.state = this.READONLY_ON;
    this.readonly.startTime = this.READONLY_START_TIME;
    this.readonly.endTime = this.READONLY_END_TIME;
  },
  methods: {
    async changeMaintenace(value) {
      const data = value ? 1 : 0;
      await updateSystemSettings("IS_CHECK_TIME", data);
    },
    async changeServiceDown(value) {
      const data = value ? 1 : 0;
      await updateSystemSettings("SERVICE_DOWN_ON", data);
    },
    async changeServiceDownState() {
      await updateSystemSettings(
        "SERVICE_START_TIME",
        this.servicedown.startTime
      );
      await updateSystemSettings("SERVICE_END_TIME", this.servicedown.endTime);
      await updateSystemSettings(
        "SERVICE_DOWN_MESSAGE",
        this.servicedown.downMessage
      );
    },
    async changeReadonlyOn(value) {
      const data = value ? 1 : 0;
      await updateSystemSettings("READONLY_ON", data);
    },
    async changeReadonlyStatus() {
      await updateSystemSettings(
        "READONLY_START_TIME",
        this.readonly.startTime
      );
      await updateSystemSettings("READONLY_END_TIME", this.readonly.endTime);
    },
  },
  computed: {
    IS_CHECK_TIME() {
      const publicSetting = this.$store.getters["settings/publicSetting"];
      if (publicSetting.IS_CHECK_TIME) {
        return true;
      }
      return false;
    },
    SERVICE_DOWN_ON() {
      const publicSetting = this.$store.getters["settings/publicSetting"];
      if (publicSetting.SERVICE_DOWN_ON) {
        return true;
      }
      return false;
    },
    SERVICE_DOW() {
      const publicSetting = this.$store.getters["settings/publicSetting"];
      if (publicSetting.SERVICE_DOW) {
        return true;
      }
      return false;
    },
    SERVICE_START_TIME() {
      const publicSetting = this.$store.getters["settings/publicSetting"];
      if (publicSetting.SERVICE_START_TIME) {
        return publicSetting.SERVICE_START_TIME;
      }
      return "09:00:00";
    },
    SERVICE_END_TIME() {
      const publicSetting = this.$store.getters["settings/publicSetting"];
      if (publicSetting.SERVICE_END_TIME) {
        return publicSetting.SERVICE_END_TIME;
      }
      return "18:00:00";
    },
    SERVICE_DOWN_MESSAGE() {
      const publicSetting = this.$store.getters["settings/publicSetting"];
      if (publicSetting.SERVICE_DOWN_MESSAGE) {
        return publicSetting.SERVICE_DOWN_MESSAGE;
      }
      return "";
    },
    READONLY_ON() {
      const publicSetting = this.$store.getters["settings/publicSetting"];
      if (publicSetting.READONLY_ON) {
        return true;
      }
      return false;
    },
    READONLY_DOW() {
      const publicSetting = this.$store.getters["settings/publicSetting"];
      if (publicSetting.READONLY_DOW) {
        return publicSetting.READONLY_DOW;
      }
      return false;
    },
    READONLY_START_TIME() {
      const publicSetting = this.$store.getters["settings/publicSetting"];
      if (publicSetting.READONLY_START_TIME) {
        return publicSetting.READONLY_START_TIME;
      }
      return false;
    },
    READONLY_END_TIME() {
      const publicSetting = this.$store.getters["settings/publicSetting"];
      if (publicSetting.READONLY_END_TIME) {
        return publicSetting.READONLY_END_TIME;
      }
      return false;
    },
  },
  data() {
    return {
      maintenance: {
        state: false,
      },
      servicedown: {
        state: false,
        startTime: "09:00:00",
        endTime: "18:00:00",
        downMessage: "",
      },
      readonly: {
        state: false,
        startTime: "09:00:00",
        endTime: "18:00:00",
        downMessage: "",
      },
    };
  },
};
</script>
